// **  Initial State
const initialState = {
  userData: {},
  notification: 0
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.data }
    case 'LOGOUT':
      return { ...state, userData: {} }
    case 'update_profile':
      return { ...state, current_profile: action.current_profile }
    case 'update_firstload_noti':
      return { ...state, notification: action.notification }
    case 'update_notification':
      return { ...state, notification: state.notification + action.notification }
    default:
      return state
  }
}

export default authReducer
