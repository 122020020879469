// **  Initial State
const initialState = {
  permission: {},
  availableModule: {}
}

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_permission':
      return { ...state, permission: action.permission }
    case 'update_available_module':
      return { ...state, availableModule: action.availableModule }
    default:
      return state
  }
}

export default permissionReducer
